import React from 'react';
import Link from 'gatsby-link';

import Icon from 'components/ui/Icon';

import * as Styled from './styles';

const TopNav = ({ slug }) => (
  <Styled.TopNavLink>
    <span>
      <Link to={slug}>
        <Icon icon="arrow-circle-left" />
        &nbsp;&nbsp;&nbsp;
        {slug}
      </Link>
    </span>
  </Styled.TopNavLink>
);

export default TopNav;
