import styled from 'styled-components';
import tw from 'twin.macro';

export const Newsletter = styled.section`
  ${tw`flex flex-col mt-auto text-black bg-white p-4 mb-12`};
`;

export const Container = styled.section`
  ${tw`flex flex-col w-full lg:w-1/3 mx-auto pt-8 px-8 border border-gray-300 rounded`};
`;

export const Title = styled.h4`
  ${tw`text-lg text-gray-900 w-full text-left`};
`;

export const Form = styled.form`
  ${tw`flex w-full flex-col sm:flex-row items-center justify-center mx-auto my-4 py-4`};
`;

export const Input = styled.input`
  ${tw`bg-white focus:outline-none focus:shadow-outline border border-gray-300 py-2 px-4 mb-2 block w-full appearance-none leading-relaxed `};
`;

export const Button = styled.button`
  outline: none !important;
  ${tw`px-4 py-2 mb-2 w-full sm:w-1/3 bg-gray-900 shadow-lg text-white hover:border-white hover:bg-purple-600`};
`;

export const Alert = styled.div`
  ${(props) =>
    props.danger
      ? tw`m-auto bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded`
      : tw`m-auto bg-green-100 border border-green-400 text-gray-700 px-4 py-3 rounded`}
`;
