import React from 'react';
import { graphql } from 'gatsby';
import Link from 'gatsby-link';
import { StickyShareButtons } from 'sharethis-reactjs';
import { DiscussionEmbed } from 'disqus-react';

import Layout from 'components/Layout';
import Newsletter from 'components/Newsletter';
import SEO from 'components/SEO';
import Container from 'components/ui/Container';
import ContentTitle from 'components/ui/ContentTitle';
import ContentNav from 'components/ui/ContentNav';
import FormatHtml from 'components/utils/FormatHtml';
import Icon from 'components/ui/Icon';
import TopNav from 'components/ui/TopNav';
import Search from 'components/ui/Search';

import * as Styled from './styles';

const searchIndices = [{ name: `pages`, title: `pages` }];

const LessonContent = ({ data, pageContext }) => {
  const lesson = data.mdx;
  const lessons = data.allMdx.edges;
  const { slug } = pageContext;
  const { page, parent, title, titleTag, description, date, type } = lesson.frontmatter;

  const next = page === lessons.length ? null : lessons[page].node;
  const previous = page === 1 ? null : lessons[page - 2].node;

  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: {
      identifier: slug,
      title: title,
      language: 'id_ID'
    }
  };


  return (
    <Layout>
      <SEO title={titleTag} description={description} />
      <Search indices={searchIndices} />
      <Container>
        <TopNav slug={`/belajar-coding/${parent}`}></TopNav>
        {type && <ContentNav lessons={lessons} />}
        <ContentTitle date={date} title={title} />
        <FormatHtml content={lesson.body} />
        <Styled.Links>
          <Styled.Span>
            {previous && (
              <Link to={previous.fields.slug} rel="previous">
                <span>
                  <Icon icon="arrow-left" />
                  &nbsp;&nbsp;&nbsp;
                </span>
                {previous.frontmatter.title}
              </Link>
            )}
          </Styled.Span>
          <Styled.Span next>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title}
                <span>
                  &nbsp;&nbsp;&nbsp;
                  <Icon icon="arrow-right" />
                </span>
              </Link>
            )}
          </Styled.Span>
        </Styled.Links>
        <StickyShareButtons
          config={{
            alignment: 'left', // alignment of buttons (left, right)
            color: 'social', // set the color of buttons (social, white)
            enabled: true, // show/hide buttons (true, false)
            font_size: 16, // font size for the buttons
            hide_desktop: false, // hide buttons on desktop (true, false)
            labels: 'counts', // button labels (cta, counts, null)
            language: 'en', // which language to use (see LANGUAGES)
            min_count: 0, // hide react counts less than min_count (INTEGER)
            networks: [
              // which networks to include (see SHARING NETWORKS)
              'facebook',
              'twitter',
              'whatsapp'
            ],
            padding: 12, // padding within buttons (INTEGER)
            radius: 0, // the corner radius on each button (INTEGER)
            show_total: true, // show/hide the total share count (true, false)
            show_mobile: true, // show/hide the buttons on mobile (true, false)
            show_toggle: true, // show/hide the toggle buttons (true, false)
            size: 48, // the size of each button (INTEGER)
            top: 160 // offset in pixels from the top of the page
          }}
        />
      </Container>
      <Newsletter />
      <Styled.DisqusContainer>
        <DiscussionEmbed {...disqusConfig} />
      </Styled.DisqusContainer>
    </Layout>
  );
};

export default LessonContent;

export const query = graphql`
  query LessonBySlug($slug: String!, $topic: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        titleTag
        description
        parent
        page
        type
        date
      }
    }
    allMdx(
      filter: { frontmatter: { category: { eq: "lesson" }, topic: { eq: $topic } } }
      sort: { fields: frontmatter___page, order: ASC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            topic
          }
        }
      }
    }
  }
`;
