import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import * as Styled from './styles';

const ContentTitle = ({ date, title }) => {
  const localDate = moment(date).tz('Asia/Jakarta').format('ll');

  return (
    <Styled.TitleSection>
      <Styled.LastModifiedDate>Terakhir diperbaharui: {localDate}</Styled.LastModifiedDate>
      <Styled.Title>{title}</Styled.Title>
    </Styled.TitleSection>
  );
};

ContentTitle.propTypes = {
  date: PropTypes.string,
  title: PropTypes.string.isRequired
};

export default ContentTitle;
