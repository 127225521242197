import React from 'react';
import ReactTooltip from 'react-tooltip';

import * as Styled from './styles';

const ContentNav = ({ lessons }) => (
  <Styled.Nav>
    {lessons.map((lesson) => {
      const {
        id,
        fields: { slug },
        frontmatter: { title }
      } = lesson.node;
      return (
        <Styled.NavItem key={id} >
          <Styled.NavLink to={slug} data-tip={title} activeClassName="active"></Styled.NavLink>
          <ReactTooltip place="top" type="dark" effect="solid"/>
        </Styled.NavItem>
      );
    })}
  </Styled.Nav>
);

export default ContentNav;
