import styled from 'styled-components';
import SearchBox from './search-box';


export default styled(SearchBox)`
  display: flex;
  flex-direction: row-reverse;
  margin: 2em 2em 0 0;

  .SearchInput {
    padding: 0.3em 1em;
    outline: none;
    border: solid 1px #e2e8f0;
    font-size: 1em;
    transition: 100ms;
    border-radius: 2px;
    color: #111111;
    ::placeholder {
      color: #e2e8f0;
    }
  }
`;
