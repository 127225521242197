import styled from 'styled-components';
import tw from 'twin.macro';
import { Link } from 'gatsby';

export const Nav = styled.ul`
  ${tw`flex w-full py-8`}
`;
export const NavItem = styled.li`
  ${tw`flex-auto mr-1`}
`;

export const NavLink = styled(Link)`
  ${tw`text-center block border py-1 bg-gray-400 hover:bg-extended-yellow-1`}

  &.active {
    ${tw`bg-extended-yellow-1 border border-extended-yellow-1`};
  }
`;
