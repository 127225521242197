import styled from 'styled-components';
import tw from 'twin.macro';

export const TitleSection = styled.div`
  ${tw`flex flex-col w-full`};
`;

export const Title = styled.h1`
  ${tw`text-3xl py-2 lg:text-4xl font-bold w-full text-left`};
`;

export const LastModifiedDate = styled.h4`
  ${tw`py-2 lg:m-auto text-sm text-blue-900 w-full text-right italic`};
`;
